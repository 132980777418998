ngb-popover-window.popover {
  --bs-popover-zindex: 99;
  --bs-popover-border-radius: var(--bs-border-radius-sm);
  --bs-popover-border-width: 0;
  box-shadow:
    0 1px 0 0 #e0e3e8,
    0 3px 6px 0 rgba(69, 98, 155, 0.12),
    0px 0px 50px rgb(0 0 0 / 10%);

  .popover-header {
    --bs-popover-inner-border-radius: var(--bs-border-radius-sm);
  }

  &.bs-popover-start > .popover-arrow,
  &.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    right: calc(-1 * var(--bs-popover-arrow-height));
  }

  &.bs-popover-top > .popover-arrow,
  &.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: calc(-1 * var(--bs-popover-arrow-height));
  }

  &.bs-popover-end > .popover-arrow,
  &.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    left: calc(-1 * var(--bs-popover-arrow-height));
  }

  &.bs-popover-bottom > .popover-arrow,
  &.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    top: calc(-1 * var(--bs-popover-arrow-height));
  }
}
