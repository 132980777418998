$blue: #2196f3 !default;
$indigo: #3f51b5 !default;
$purple: #9c27b0 !default;
$pink: #e91e63 !default;
$red: #f44336 !default;
$orange: #ff9800 !default;
$yellow: #ffeb3b !default;
$green: #4caf50 !default;
$teal: #009688 !default;
$cyan: #00bcd4 !default;

$primary: #005758 !default;
$secondary: #088597 !default;
$success: #0ab39c !default;
$info: #299cdb !default;
$warning: #f7b84b !default;
$danger: #f06548 !default;
$light: #f3f6f9 !default;
$dark: #212529 !default;

$body-color: #212529;
$body-bg: #fff;

$border-radius: 0.15rem !default;

$font-size-base: 0.875rem !default;
$font-family-sans-serif:
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;

$transition-duration: 0.2s;
