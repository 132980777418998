// Width Fit Content
.min-w-fit-content {
  min-width: fit-content;
}

.w-fit-content {
  width: fit-content;
}

.max-w-fit-content {
  max-width: fit-content;
}

// Width Min Content
.min-w-min-content {
  min-width: min-content;
}

.w-min-content {
  width: min-content;
}

.max-w-min-content {
  max-width: min-content;
}

// Width Max Content
.min-w-max-content {
  min-width: max-content;
}

.w-max-content {
  width: max-content;
}

.max-w-max-content {
  max-width: max-content;
}

// Width 100vh
.min-w-100vh {
  min-width: 100vh;
}

.w-100vh {
  width: 100vh;
}

.max-w-100vh {
  max-width: 100vh;
}

// Height Fit Content
.min-h-fit-content {
  min-height: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.max-h-fit-content {
  max-height: fit-content;
}

// Height Min Content
.min-h-min-content {
  min-height: min-content;
}

.h-min-content {
  height: min-content;
}

.max-h-min-content {
  max-height: min-content;
}

// Height Max Content
.min-h-max-content {
  min-height: max-content;
}

.h-max-content {
  height: max-content;
}

.max-h-max-content {
  max-height: max-content;
}

// Height 100vh
.min-h-100vh {
  min-height: 100vh;
}

.h-100vh {
  height: 100vh;
}

.max-h-100vh {
  max-height: 100vh;
}
