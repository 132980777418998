// PrimeNG
.p-dropdown-panel .p-dropdown-items,
.p-column-filter-row-items {
  margin-bottom: 0px;
  padding: 0px;
}

.p-inputtext {
  font-size: 1em;
}

.p-dropdown span[role="combobox"] .ng-star-inserted {
  display: flex;
  height: 100%;
  font-size: 1em;
  flex-direction: column;
  justify-content: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--bs-primary) !important;
}

.p-overlay {
  left: -1px !important;

  ul[role="listbox"] {
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
}

// Bootstrap
.btn-outline-primary {
  --bs-btn-hover-color: #fff;
}

// Theme
.h-50px {
  height: 50px !important;
}

section.hero {
  position: relative;
  background-size: cover;
  background-position: center;

  &.hero-overlay::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    // background-color: #000;
    background: linear-gradient(135deg, rgba(var(--bs-primary-rgb)) 0%, rgba(var(--bs-secondary-rgb)) 100%);
    filter: contrast(120%) saturate(125%);
    mix-blend-mode: overlay;
    opacity: 0.6;
    z-index: 0;
  }

  .hero-title {
    letter-spacing: -3px;
    line-height: 36px;
    text-shadow: 0px 0px 100px black;
  }

  .hero-text {
    opacity: 0.8;
    font-weight: 300;
    text-shadow: 0px 0px 25px black;
  }
}
